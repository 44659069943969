* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

input:focus {
  outline: none;
}

input[type='file'],
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

button {
  background: transparent;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Montserrat', sans-serif;
}

body > #root > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1050px) {
  body > #root > div {
    height: auto;
    min-height: 100vh;
  }
}

.active {
  opacity: 1;
}

.plyr {
  height: 400px;
  max-height: 100%;
}

.primary-btn {
  font-size: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 13px 44px;
  cursor: pointer;
  background: #9d1091;
  border-radius: 15px;
}

.secondary-btn {
  font-size: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #9d1091;
  padding: 13px 44px;
  cursor: pointer;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #9d1091;
  box-shadow: 0px 3px 6px #00000029;
}

.primary-input {
  width: 100%;
  border: 1px solid #001194;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  padding: 13px 16px;
}

@keyframes in-out {
  0% {
    width: 0px;
  }
  25% {
    width: 25px;
  }
  50% {
    width: 55px;
  }
  75% {
    width: 75px;
  }
  100% {
    width: 75px;
  }
}

@keyframes increaseDot {
  0% {
    height: 13px;
    width: 13px;
  }

  65% {
    height: 19px;
    width: 19px;
  }

  100% {
    height: 13px;
    width: 13px;
  }
}


/* switch toggle button */

.switch-around {
  margin: 2px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 80%;
  width: 50%;
  left: 10%;
  bottom: 12%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #9d1091;
}

input:focus + .slider {
  box-shadow: 0 0 1px #9d1091;
}

input:checked + .slider:before {
  -webkit-transform: translateX(60%);
  -ms-transform: translateX(60%);
  transform: translateX(60%);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch.title {
  display: inline-block;
  position: absolute;
  left: 100%;
  white-space: nowrap;
}
